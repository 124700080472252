<template>
	<div
		class="bg-app-blue z-50 py-6 border-t md:rounded-b-xl flex items-center justify-around border-radial-1-card w-full h-24"
		v-if="displayFooter"
	>
		<NuxtLink
			@click="$captureEvent(`footer_tabs_goto_${tab.id}`)"
			v-for="tab in tabs"
			:key="tab.id"
			:to="tab.href"
			class="flex flex-col items-center justify-center relative"
			:class="{
				'text-footer-active-link': tab.active,
			}"
		>
			<span class="material-icons"> {{ tab.icon }} </span>
			<span class="text-sm font-medium">{{ $t(tab.labelI18Key) }}</span>
			<div
				class="h-2 w-2 bg-red-500 rounded-full animate-pulse absolute top-0 right-1"
				v-if="
					tab.labelI18Key === 'footerTabs.menu' &&
					hasUnreadNotifications &&
					(hasProcessingActions?.model || hasProcessingActions?.prediction)
				"
			/>
			<div
				class="h-2 w-2 bg-gray-400 animate-pulse rounded-full absolute top-0 right-1"
				v-if="
					tab.labelI18Key === 'footerTabs.menu' &&
					!hasUnreadNotifications &&
					(hasProcessingActions?.model || hasProcessingActions?.prediction)
				"
			/>
		</NuxtLink>
	</div>
</template>

<script setup lang="ts">
const configStore = useConfigStore()
const route = useRoute()

const tabs = ref([
	{
		id: 'my_account',
		labelI18Key: 'footerTabs.home',
		href: '/',
		icon: 'home',
		active: false,
	},
	{
		id: 'explore',
		labelI18Key: 'footerTabs.explore',
		href: '/explore',
		icon: 'explore',
		active: false,
	},
	{
		id: 'new_avatar',
		labelI18Key: 'footerTabs.create',
		href: '/images/create',
		icon: 'auto_awesome',
		active: false,
	},
	{
		id: 'menu',
		labelI18Key: 'footerTabs.menu',
		href: '/my-account/menu',
		activeHrefAlias: [
			'/my-account/notifications',
			'/my-account/library',
			'/my-account/settings',
			'/my-account/subscription',
		],
		icon: 'menu',
		active: false,
	},
])

const disabledPaths = ['/checkout/plans']

const displayFooter = computed(() => configStore.displayFooter)
const hasProcessingActions = computed(() => configStore.pendingJobs)
const hasUnreadNotifications = computed(() => configStore.hasUnreadNotifications)

function checkTabs() {
	const currentPath = route.path
	const isToolPage = currentPath.includes('/tools/')
	const isDisabled = disabledPaths.includes(currentPath) || isToolPage

	configStore.setDisplayFooter(!isDisabled)

	tabs.value.forEach(tab => {
		const isActive = tab.href === currentPath
		const isAliasActive = tab.activeHrefAlias && tab.activeHrefAlias.includes(currentPath)

		tab.active = isActive || (isAliasActive as boolean)
	})
}

watch(() => route.fullPath, checkTabs)

onMounted(() => {
	checkTabs()
})
</script>
