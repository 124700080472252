<template>
	<div v-if="displayModal">
		<div class="bg-black/80 fixed w-screen h-screen top-0 left-0 z-10"></div>

		<transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 translate-y-48"
			enter-to-class="transform opacity-100"
		>
			<div
				class="fixed md:rounded-t-xl max-w-[592px] md:left-1/2 md:-translate-x-1/2 h-max w-full bottom-0 left-0 bg-black border border-gray-900 z-[60]"
			>
				<div class="py-8 flex flex-col items-center justify-center px-6">
					<img
						src="https://cdn.realitystudio.ai/assets/web/icon-logo-white.png"
						alt="logo"
						class="h-full max-w-[80px] max-h-[80px] object-cover mb-4"
					/>
					<div class="font-satoshi text-center px-4">
						<h2 class="text-xl mb-4">{{ feedbackText.title }}</h2>
						<p class="text-gray-600" v-html="feedbackText.description"></p>
					</div>
				</div>
				<div v-if="feedbackText.isReason" class="px-4 py-6 flex justify-center">
					<DefaultTextArea
						name="reason"
						id="reason"
						:placeholder="$t('feedbackModal.reasonPlaceholder')"
						rows="4"
						class="w-full"
						v-model="reason"
						:labelText="$t('feedbackModal.reason')"
						:deleteButton="false"
					/>
				</div>
				<div class="flex flex-col">
					<DefaultButton
						class="border-t border-gray-900 py-6 px-4"
						color="none"
						buttonClass="font-normal"
						v-for="(button, index) in feedbackText.buttons"
						:key="index"
						@click="button.action"
					>
						<p>{{ button.label }}</p>
					</DefaultButton>
				</div>
			</div>
		</transition>
	</div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const displayModal = ref(false)
const showText = ref('firstStep')
const reason = ref('')
const feedback = ref('')
const entity = ref({})
const defaultDisplayTimerInSeconds = 5
const { user: loggedUser } = useUserStore()

const { t } = useI18n()

const feedbackTexts = {
	firstStep: {
		isFeedbackOptions: true,
		isReason: false,
		title: t('feedbackModal.firstStep.title'),
		description: t('feedbackModal.firstStep.description'),
		buttons: [
			{
				action: () => handleFeedbackClick('positive'),
				label: t('feedbackModal.firstStep.positive'),
			},
			{
				action: () => handleFeedbackClick('negative'),
				label: t('feedbackModal.firstStep.negative'),
			},
			{
				action: () => handleFeedbackClick('ignored'),
				label: t('feedbackModal.cancel'),
			},
		],
	},
	secondStep: {
		isFeedbackOptions: false,
		isReason: false,
		title: t('feedbackModal.secondStep.title'),
		description: t('feedbackModal.secondStep.description'),
		buttons: [
			{
				action: () => goToPlayStoreReview(),
				label: t('feedbackModal.secondStep.feedback'),
			},
			{
				action: () => close(),
				label: t('feedbackModal.cancel'),
			},
		],
	},
	negativeStep: {
		isFeedbackOptions: false,
		isReason: true,
		title: t('feedbackModal.secondStep.title'),
		description: t('feedbackModal.secondStep.description'),
		buttons: [
			{
				action: () => handleSendReason(),
				label: t('feedbackModal.reasonSubmit'),
			},
			{
				action: () => close(),
				label: t('feedbackModal.cancel'),
			},
		],
	},
}

const feedbackText = computed(() => feedbackTexts[showText.value])

const close = () => {
	displayModal.value = false
}

const checkShowFeedbackModal = async () => {
	if (!loggedUser) return

	try {
		const result = await $api('/feedback/check', { method: 'GET' })
		setTimeout(() => {
			displayModal.value = result.displayModal
		}, defaultDisplayTimerInSeconds * 1000)
	} catch (error) {
		$sentryCaptureException(error)
		displayModal.value = false
	}
}

const handleFeedbackClick = selectedFeedback => {
	feedback.value = selectedFeedback

	if (feedback.value === 'negative') {
		showText.value = 'negativeStep'
		return
	}

	sendFeedback(feedback.value)
}

const handleSendReason = () => {
	sendFeedback(feedback.value, reason.value)
}

const sendFeedback = async (selectedFeedback, reasonText = null) => {
	try {
		await $api('/feedback', {
			method: 'POST',
			body: {
				type: selectedFeedback,
				entityMetadata: JSON.stringify(entity.value),
				reason: reasonText,
			},
		})
	} catch (error) {
		$sentryCaptureException(error)
	}

	if (selectedFeedback === 'ignored' || selectedFeedback === 'negative') {
		close()
		return
	}

	showText.value = 'secondStep'
}

const goToPlayStoreReview = () => {
	window.open('https://play.google.com/store/apps/details?id=net.realityavatar.app&pcampaignid=web_share', '_blank')
}

onMounted(() => {
	$mitt.on('openFeedbackModal', async receivedEntity => {
		entity.value = receivedEntity
		checkShowFeedbackModal()
	})
})
</script>
